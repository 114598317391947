'use client';

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ArrowDropDownRounded, ArrowDropUpRounded, ArrowForwardRounded } from '@mui/icons-material';
import { ButtonProps, useOnClickOutside } from '@sortlist-frontend/design-system';
import { Button } from '@sortlist-frontend/design-system/ssr';
import { useDebounceCallback } from '@sortlist-frontend/utils';
import { CSSProperties, Fragment, ReactElement, useRef, useState } from 'react';

import { Obfuscate } from '_components/common/Obfuscate/Obfuscate';

export type MenuItemm = {
  href: string;
  visible: boolean;
  label: string | ReactElement;
  dataTestid?: string;
  className?: string;
};

type Props = {
  items: MenuItemm[];
  showArrow?: boolean;
  menuClassName?: string;
  dataTestId?: string;
  menuStyle?: CSSProperties;
} & ButtonProps;

export const Menu = (props: Props) => {
  const {
    items,
    menuClassName,
    showArrow = true,
    dataTestId,
    className = 'flex-none small px-4',
    buttonStyle = 'secondary',
    buttonVariant = 'default',
    menuStyle,
    ...buttonProps
  } = props;
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);

  // ensure menu is not flickering when clicking on button and click outside also is triggered
  const openMenu = useDebounceCallback((open: boolean) => setOpen(open), 70);

  useOnClickOutside(menuRef, () => {
    openMenu(false);
  });

  return (
    <div className="relative">
      <Button
        data-testid={dataTestId ?? 'menu-button'}
        className={className}
        animation="none"
        size="sm"
        iconSize="lg"
        buttonVariant={buttonVariant}
        buttonStyle={buttonStyle}
        iconRight={
          showArrow ? (
            open ? (
              <ArrowDropUpRounded className="ml-8" />
            ) : (
              <ArrowDropDownRounded className="ml-8" />
            )
          ) : undefined
        }
        onClick={() => {
          openMenu(!open);
        }}
        id="find-agencies-btn"
        {...buttonProps}
      />
      {open ? (
        <div
          ref={menuRef}
          className={`${menuClassName} menu absolute bg-neutral-100 rounded-md layout-column layout-align-start-center shadow-2`}
          style={{ zIndex: 1000, ...menuStyle }}>
          {items.map((item, index) => (
            <Fragment key={item.href}>
              <Obfuscate
                data-testid={item.dataTestid ?? item.label}
                className={`${item.className ?? ''} py-12 px-16 small width-100 layout-row layout-align-space-between-center lh-2`}
                href={item.href}
                obfuscate={true}>
                <span>{item.label}</span>
                <ArrowForwardRounded className="text-secondary-500 p ml-8" />
              </Obfuscate>
              {index < items.length - 1 ? <hr className="divider width-100" /> : null}
            </Fragment>
          ))}
        </div>
      ) : null}
    </div>
  );
};
