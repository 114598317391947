import { TFunction } from '@sortlist-frontend/translation/server-only';
import { CSSProperties, FC, ForwardedRef, ReactElement, ReactNode } from 'react';

import { Expertise } from '_core/repos/expertises.repo';
import { Links } from '_types/public-api';

import { Footer } from './Footer';
import { Main } from './Main';
import { PrimaryNav } from './PrimaryNav/PrimaryNav';

export type NavigationData = {
  origin: string;
  locale: string;
  resolvedUrl?: string;
  languageSwitcthRedirectHome?: boolean;
};

// Layout props, router agnostic
export type LayoutProps = {
  children: ReactNode;
  className?: string;
  scrollableCtn?: ForwardedRef<HTMLDivElement>;
  nav?: boolean;
  showFooter?: boolean;
  minimalHeader?: ReactElement;
  showSearch?: boolean;
  showSubnav?: boolean;
  showRankUpdated?: boolean;
  style?: CSSProperties;
};

// Layout elements and data, based on router/what kind of experience we want to provide
export type LayoutElementsProps = {
  briefingCTA: ReactElement;
  briefingAdviceCTA: ReactElement;
  comparatorCTA: ReactElement;
  search: ReactElement | null;
  searchMobile: ReactElement;
  navigationData: NavigationData;
  t: TFunction;
  expertises?: Expertise[];
  links?: Links;
};

/**
 * This will help react-lazyload to listen to scrolling position on the content area
 * Change it wisely cause it can break lazy loading in the public-app
 */
export const publicMainScrollableContentCtn = 'public-main-scrollable-content-ctn';

export const Layout: FC<LayoutProps & LayoutElementsProps> = (props) => {
  const {
    children,
    scrollableCtn,
    nav = true,
    className,
    style,
    minimalHeader,
    showRankUpdated,
    showFooter = true,
    ...navProps
  } = props;

  return (
    <div className={`${className ?? ''} layout-column layout-fill`} style={style}>
      {nav ? minimalHeader ?? <PrimaryNav {...navProps} /> : null}

      <Main scrollableCtn={scrollableCtn} id={publicMainScrollableContentCtn}>
        {children}
        {showFooter ? (
          <Footer showRankUpdated={showRankUpdated} navigationData={navProps.navigationData} t={navProps.t} />
        ) : null}
      </Main>
    </div>
  );
};
