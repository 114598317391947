'use client';

import { useBriefingMessage } from '_components/Briefing/versions/NormalBriefing/BriefingDialog';
import { Expertise } from '_core/repos/expertises.repo';

type Props = {
  expertises?: Expertise[];
};

export const ExpertiseList = (props: Props) => {
  const { expertises } = props;

  const { sendMessage } = useBriefingMessage();

  return (
    <ul className="list-reset small">
      {expertises?.map((expertise, index: number) => {
        return (
          <li className="mb-8 top-link-anchor" key={`${index}-${expertise.slug}`}>
            <button
              className="small mb-8"
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                fontWeight: 400,
                padding: '0',
                textAlign: 'left',
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
                textDecoration: 'none',
              }}
              onClick={() => {
                sendMessage('open-briefing', { cta: 'navbar-project', expertiseId: expertise.expertiseId });
              }}>
              {expertise.name}
            </button>
          </li>
        );
      })}
    </ul>
  );
};
