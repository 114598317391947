'use client';

import { FC, ReactElement, useState } from 'react';

import { MobileMenu } from './MobileMenu/MobileMenu';

export type SearchMenuProps = {
  title: string;
  search: ReactElement;
};

export const SearchMenu: FC<SearchMenuProps> = (props) => {
  const { search, title } = props;

  const [opened, setOpened] = useState(false);

  return (
    <MobileMenu opened={opened} toggleMenu={() => setOpened(!opened)}>
      <div className="layout-column layout-align-start-stretch" role="menu">
        {/* <p className="h4 bold pl-32 text-secondary-900 pb-32">{t('common:navbar.menu.search')}</p> */}
        <p className="h4 bold pl-32 text-secondary-900 pb-32">{title}</p>
        <div className="px-32">
          {search}
          {/* <SearchCta
            cta="hero"
            showIndustry={false}
            page="search-landing"
            version={'navbarMobile'}
            element="search-landing"
            locationAutocompletePlaceholder={t('common:navbar.menu.where')}
            keywordSelectorPlaceholder={t('common:navbar.menu.whatService')}
          /> */}
        </div>
      </div>
    </MobileMenu>
  );
};
