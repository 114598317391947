'use client';

import { useBriefingMessage } from '_components/Briefing/versions/NormalBriefing/BriefingDialog';

type Props = {
  label: string;
};

export const BriefingCta = (props: Props) => {
  const { label } = props;

  const { sendMessage } = useBriefingMessage();
  return (
    <button
      onClick={() => {
        sendMessage('open-briefing', { cta: 'footer' });
      }}
      aria-label={label}
      className="text-secondary-700"
      style={{
        border: 'none',
        outline: 'none',
        background: 'transparent',
        margin: 0,
        padding: 0,
      }}>
      {label}
    </button>
  );
};
