'use client';

import { ArrowForwardRounded, MenuRounded } from '@mui/icons-material';
import { FC, Fragment, ReactElement, useState } from 'react';

import { useBriefingMessage } from '_components/Briefing/versions/NormalBriefing/BriefingDialog';
import { Links } from '_types/public-api';

import { ExploreMenu } from './ExploreMenu';
import { MobileMenu } from './MobileMenu/MobileMenu';
import { MobileMenuHeader } from './MobileMenu/MobileMenuHeader';
import { MobileMenuItem } from './MobileMenu/MobileMenuItem';

type Props = {
  className?: string;
  children: ReactElement;
  findAgenciesMenu: {
    title: string;
    explore: {
      title: string;
      subtitle: string;
    };
    postAProject: {
      title: string;
      subtitle: string;
    };
    getAdvice: {
      title: string;
      subtitle: string;
    };
  };
  exploreMenu: {
    title: string;
  };
  links?: Links;
};

export const MainMenu: FC<Props> = (props) => {
  const { children, findAgenciesMenu, exploreMenu, links } = props;
  const [menu, setMenu] = useState<boolean>(false);
  const [exploreMenuOpen, setExploreMenuOpen] = useState<boolean>(false);
  const [showIntercom, setShowIntercom] = useState<boolean>(true);

  const { sendMessage } = useBriefingMessage();

  const toggleMenu = () => {
    setMenu((prev) => !prev);
    setExploreMenuOpen(false);
    setExploreMenuOpen(false);
    setShowIntercom((prev) => !prev);

    if (window.Intercom) {
      window.Intercom('update', {
        hide_default_launcher: showIntercom,
      });
    }
  };

  return (
    <Fragment>
      <button
        onClick={toggleMenu}
        aria-label="toggle-menu"
        className="bg-neutral-100 layout-column layout-align-center-center text-secondary-500 border-none p-0 m-0"
        id="toggle-menu-btn">
        <MenuRounded className="text-secondary-500" style={{ fontSize: 24 }} />
      </button>
      <MobileMenu opened={menu} toggleMenu={toggleMenu}>
        <Fragment>
          <section id="find-agencies-menu">
            <MobileMenuHeader title={findAgenciesMenu.title} />
            <ul className="list-reset">
              <li onClick={() => setExploreMenuOpen(true)} tabIndex={0} onKeyDown={() => {}} role="menuitem">
                <MobileMenuItem
                  dataTestid="navbar-explore-agencies"
                  title={findAgenciesMenu.explore.title}
                  subtitle={findAgenciesMenu.explore.subtitle}
                  className="border-bottom border-secondary-300"
                  iconRight={<ArrowForwardRounded className="text-secondary-500" style={{ fontSize: 16 }} />}
                  wrapLi={false}
                />
              </li>
              <li
                onClick={() => {
                  sendMessage('open-briefing', { cta: 'navbar-project' });
                }}
                tabIndex={0}
                onKeyDown={() => {}}
                role="menuitem">
                <MobileMenuItem
                  dataTestid="navbar-post-a-project"
                  title={findAgenciesMenu.postAProject.title}
                  subtitle={findAgenciesMenu.postAProject.subtitle}
                  className="border-bottom border-secondary-300"
                  wrapLi={false}
                />
              </li>
              <li
                onClick={() => {
                  sendMessage('open-briefing', { cta: 'navbar-advice' });
                }}
                tabIndex={0}
                onKeyDown={() => {}}
                role="menuitem">
                <MobileMenuItem
                  dataTestid="navbar-get-advice"
                  title={findAgenciesMenu.getAdvice.title}
                  subtitle={findAgenciesMenu.getAdvice.subtitle}
                  className="border-bottom border-secondary-300"
                  wrapLi={false}
                />
              </li>
            </ul>
          </section>
          {children}
        </Fragment>
      </MobileMenu>

      <ExploreMenu
        opened={exploreMenuOpen}
        toggleMenu={toggleMenu}
        closeExploreMenu={() => setExploreMenuOpen(false)}
        title={exploreMenu.title}
        links={links}
      />
    </Fragment>
  );
};
