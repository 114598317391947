import { useEffect } from 'react';

export type OnReceiveMessage<T, K> = (action: T, data: K) => void;

export const useWindowMessage = <T, K>(params?: { onReceiveMessage: OnReceiveMessage<T, K> }) => {
  const { onReceiveMessage } = { ...params };

  useEffect(() => {
    if (onReceiveMessage == null) return;

    const handleMessage = (event: MessageEvent) => {
      const { action, data } = event.data;
      onReceiveMessage(action, data);
    };
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const sendMessage = (action: T, data?: K) => {
    window.postMessage({ action, data }, '*');
  };

  const sendMessageToParent = (action: T, data?: K) => {
    window.parent.postMessage({ action, data }, '*');
  };

  return { sendMessage, sendMessageToParent };
};
