import Image from 'next/image';

import { Obfuscate } from '_components/common/Obfuscate/Obfuscate';

const s3BucketUrl = process.env.NEXT_PUBLIC_S3_BUCKET;

const facebookIcon = `${s3BucketUrl}/icons/social/facebook.svg`;
const instagramIcon = `${s3BucketUrl}/icons/social/instagram.svg`;
const linkedinIcon = `${s3BucketUrl}/icons/social/linkedin.svg`;
const twitterIcon = `${s3BucketUrl}/icons/social/twitter.svg`;
const youtubeIcon = `${s3BucketUrl}/icons/social/youtube.svg`;

const getSocialUrl = (social: string) => {
  const socials = {
    twitter: 'https://twitter.com/sortlist',
    facebook: 'https://www.facebook.com/wearesortlist',
    linkedin: 'https://www.linkedin.com/company/sortli-st/',
    instagram: 'https://www.instagram.com/sortlist/',
    youtube: 'https://www.youtube.com/channel/UCsougkJ2hHCTWJD_eywEHpA',
  };

  return socials[social as keyof typeof socials];
};

export const Socials = () => {
  const socials = [
    { alt: 'Twitter', href: getSocialUrl('twitter'), src: twitterIcon },
    { alt: 'Facebook', href: getSocialUrl('facebook'), src: facebookIcon },
    { alt: 'Linkedin', href: getSocialUrl('linkedin'), src: linkedinIcon },
    { alt: 'Instagram', href: getSocialUrl('instagram'), src: instagramIcon },
    { alt: 'YouTube', href: getSocialUrl('youtube'), src: youtubeIcon },
  ];
  return (
    <div className="layout-row gap-x-8 my-32">
      {socials.map((social, index) => {
        return (
          <Obfuscate
            href={social.href}
            className={`${index + 1 !== socials.length ?? 'mr-8'} p-8 bg-secondary-300 rounded layout-column layout-align-center-center`}
            key={social.alt}
            obfuscate={true}>
            <Image className="social-icon" src={social.src} alt={social.alt} width={12} height={12} />
          </Obfuscate>
        );
      })}
    </div>
  );
};
